<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="tutorialApi.isError" :api="tutorialApi" />
          <!-- EOC -->
          <FinishedBanner v-if="finished"></FinishedBanner>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    FinishedBanner: () =>
      import(
        /* webpackChunkName: "component-mini-game-finished-banner" */ "@/components/games/FinishedBanner"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    event: (state) => state.event.data,
    tutorial: (state) => state.tutorial.data,
    series: (state) => state.series.data,
  }),
  props: [
    //
  ],
  data: () => ({
    finished: true,
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    //
  },
  mounted() {
    var tutExploreMining = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreMining";
    });

    var tutExploreForaging = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreForaging";
    });

    var tutExploreCooking = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreCooking";
    });

    var tutExploreCooking2 = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreCooking2";
    });

    var tutExploreKombat = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreKombat";
    });

    var tutExploreFishing = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreFishing";
    });

    if (
      (this.series.gameCode == "GEMS" && tutExploreMining.length > 0) ||
      (this.series.gameCode == "FRUIT" && tutExploreForaging.length > 0) ||
      (this.series.gameCode == "COOK" && tutExploreCooking.length > 0) ||
      (this.series.gameCode == "COOK2" && tutExploreCooking2.length > 0) ||
      (this.series.gameCode == "BATTLE" && tutExploreKombat.length > 0) ||
      (this.series.gameCode == "FISH" && tutExploreFishing.length > 0) 
    ) {
      setTimeout(() => this.next(), 5000);
    } else {
      this.updateTutorial();
    }
  },
  methods: {
    next() {
      this.$router.push({
        name: "PageEventLevels",
      });
    },
    updateTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/complete";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        this.$store.commit("updateTutorial", resp);
        setTimeout(() => this.next(), 5000);
      };
      if (this.tutorial.Ongoing == "tutExploreMining") {
        this.tutorialApi.params = {
          key: "tutExploreMining",
        };
      }
      if (this.tutorial.Ongoing == "tutExploreForaging") {
        this.tutorialApi.params = {
          key: "tutExploreForaging",
        };
      }
      if (this.tutorial.Ongoing == "tutExploreCooking") {
        this.tutorialApi.params = {
          key: "tutExploreCooking",
        };
      }
      if (this.tutorial.Ongoing == "tutExploreCooking2") {
        this.tutorialApi.params = {
          key: "tutExploreCooking2",
        };
      }
      if (this.tutorial.Ongoing == "tutExploreKombat") {
        this.tutorialApi.params = {
          key: "tutExploreKombat",
        };
      }
      if (this.tutorial.Ongoing == "tutExploreFishing") {
        this.tutorialApi.params = {
          key: "tutExploreFishing",
        };
      }
      this.$api.fetch(this.tutorialApi);
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>